<template>
  <v-dialog
    v-model="modalData.dialog"
    persistent
    scrollable
    max-width="650px"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="ml-4 mb-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("FORMS.new") }} {{ $t("FORMS.userGroup") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.system.userGroup"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :rules="nameRules"
                :label="$t('FORMS.userGroupName')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="formModel.users"
                :items="modalData.users"
                :label="$t('FORMS.select_users')"
                :multiple="true"
                item-text="name"
                item-value="id"
                chips
                deletable-chips="true"
                persistent-hint
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.users)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12"> </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";
const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  id: "",
  status: 1,
  translations: {},
  users: [],
};

export default {
  name: "UserGroupForm",
  props: ["modalData", "statuses", "permissions"],
  data() {
    return {
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
    };
  },
  mounted: function () {},
  computed: {
    formTitle() {
      return this.modalData.editedIndex === -1
        ? this.$t("FORMS.new")
        : this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name;
    },
    // formModel() {
    //   this.setTranslatedAttributes;
    //   return this.modalData.editedItem;
    // },

    dynamicID() {
      // return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString());

      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },

  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },

  methods: {
    handleCloseModalForm() {
      this.formModel = Object.assign({}, initialFormData);
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.userGroup." + action
      );
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
